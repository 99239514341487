/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * Implementing in /containers/App/index.js
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { wrapApp, onAppRouteUpdate } from 'containers/App';

export const wrapRootElement = wrapApp;
export const onRouteUpdate = onAppRouteUpdate;


export const shouldUpdateScroll = () => {
  window.scrollTo(0,0);
  return false;
};