import {
  SET_DIMENSIONS,
  SET_SCROLL_TOP,
  OPEN_MENU,
  TOGGLE_MENU,
  SET_IS_TOUCH_DEVICE,
  SET_IS_HOVER_DEVICE,
} from 'actions/display';

const initialState = {
  dimensions: {
    width: (typeof window !== 'undefined' && window.width) || 0,
    height: (typeof window !== 'undefined' && window.height) || 0,
  },
  scrollTop: 0,
  menuIsOpen: false,
  isTouchDevice: false,
  isHoverDevice: false,
};

export default function displayReducer(state = initialState, action) {
  const { type, ...actions } = action;
  switch (type) {
    case SET_DIMENSIONS:
      return {
        ...state,
        dimensions: {
          width: actions.width,
          height: actions.height,
        },
      };
    case SET_SCROLL_TOP:
      return {
        ...state,
        scrollTop: action.scrollTop,
      };
    case OPEN_MENU:
      return {
        ...state,
        menuIsOpen: actions.isOpen,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen,
      };
    case SET_IS_TOUCH_DEVICE:
      return {
        ...state,
        isTouchDevice: action.isTouchDevice,
      };
    case SET_IS_HOVER_DEVICE:
      return {
        ...state,
        isHoverDevice: action.isHoverDevice,
      };
    default:
      return state;
  }
}
