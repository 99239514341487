/**
 *
 * LogoIcon
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

import theme from 'utils/theme';

export const LogoIcon = ({
  fill = theme.colors.lightGreen,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    // width="720"
    // height="288"
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 720 288"
  >
    <defs>
      <clipPath id="clip-Artboard_1">
        <rect width="720" height="288" />
      </clipPath>
    </defs>
    <g id="Artboard_1" data-name="Artboard 1" clipPath="url(#clip-Artboard_1)">
      <g id="Group_3" data-name="Group 3">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M154.392,40.561h-.011c-9.485.146-17.378,7.875-17.122,17.387.29,10.731,15.907,33.717,17.122,35.489v.016l.006-.008.005.008v-.016c1.215-1.772,16.833-24.758,17.122-35.489C171.771,48.436,163.878,40.707,154.392,40.561Zm-.005,25.495a9.306,9.306,0,1,1,8.985-9.3A9.307,9.307,0,0,1,154.387,66.056Z"
          fill={fill}
        />
        <g id="Group_2" data-name="Group 2">
          <path
            id="Path_2"
            data-name="Path 2"
            d="M106.757,105.19q12.667,0,16.748,6.226,0,7.521-2.362,10.952t-5.8,3.435q-3.866,0-7.086-3.65a11.145,11.145,0,0,0-8.8-3.651q-6.657,0-11.594,6.228a20.771,20.771,0,0,0-4.938,13.1v32.208q0,12.456,1.717,19.11.428,1.506,5.69,2.9a33.976,33.976,0,0,0,7.408,1.395c.43,0,.714.86.859,2.577a8.935,8.935,0,0,1-.215,3.651q-21.474-1.073-23.19-1.074-1.287,0-23.4,1.074c-.574-.574-.859-1.752-.859-3.543s.285-2.685.859-2.685a31.438,31.438,0,0,0,7.623-1.395q5.042-1.394,5.475-2.9a65.01,65.01,0,0,0,1.5-15.029V133.533q0-8.375-3.006-11.6a10.985,10.985,0,0,0-5.261-3.543,18.142,18.142,0,0,0-5.153-.966c-1.362,0-2.04-.141-2.04-.429q0-5.152,1.288-5.369,22.757-3.43,29.632-5.582a6.4,6.4,0,0,0,1.074-.214q.855,0,1.073,1.825a8.294,8.294,0,0,1,0,2.684l-1.288,10.735a44.645,44.645,0,0,1,10.628-10.844Q100.1,105.194,106.757,105.19Z"
            fill="#fff"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M164.3,170.035q0,12.456,1.717,19.11.429,1.506,5.584,2.9a32.636,32.636,0,0,0,7.3,1.395q.644,0,.859,2.577a8.935,8.935,0,0,1-.215,3.651q-21.474-1.073-22.975-1.074t-23.4,1.074c-.573-.574-.858-1.752-.858-3.543s.285-2.685.858-2.685a31.43,31.43,0,0,0,7.623-1.395q5.043-1.394,5.475-2.9a65.005,65.005,0,0,0,1.5-15.029V133.533q0-8.375-3.007-11.6a10.987,10.987,0,0,0-5.26-3.543,18.147,18.147,0,0,0-5.154-.966c-1.362,0-2.039-.141-2.039-.429q0-5.152,1.288-5.369,22.758-3.43,29.631-5.582l.429-.214h.645q.855,0,1.074,1.825a8.353,8.353,0,0,1,0,2.684A125.205,125.205,0,0,0,164.3,125.8Z"
            fill="#fff"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M240.957,105.4q20.613,0,32.208,13.85t11.6,29.523q0,20.614-14.388,36.288T237.736,200.74c-2.146,0-4.046-.074-5.69-.215a24.614,24.614,0,0,1-4.4-.751,31.116,31.116,0,0,1-3.008-.966c-.718-.286-1.646-.678-2.791-1.181s-1.861-.822-2.146-.966a4.328,4.328,0,0,0-.859,2.791v18.466q0,12.45,1.718,19.11.429,1.5,5.584,2.9a32.758,32.758,0,0,0,7.3,1.4c.43,0,.715.892.859,2.683a7.977,7.977,0,0,1-.216,3.543q-21.474-1.077-22.974-1.073-1.288,0-23.4,1.073-.859-.861-.858-3.543c0-1.791.285-2.683.858-2.683a31.535,31.535,0,0,0,7.623-1.4q5.043-1.4,5.476-2.9a65.059,65.059,0,0,0,1.5-15.031V133.318q0-8.374-3.007-11.595a10.969,10.969,0,0,0-5.26-3.542,18.119,18.119,0,0,0-5.154-.967c-1.361,0-2.039-.141-2.039-.429q0-5.153,1.288-5.369,22.757-3.431,29.632-5.581a6.441,6.441,0,0,0,1.073-.215c.429,0,.646.395.646,1.181a24.279,24.279,0,0,1-.217,2.9c-.144,1.148-.213,1.792-.213,1.932v1.289a48.192,48.192,0,0,1,9.984-4.832A34.276,34.276,0,0,1,240.957,105.4Zm-6.872,10.52a16.634,16.634,0,0,0-10.95,3.543q-4.3,3.543-4.294,10.2V177.98q0,5.8,6.226,9.769a24.647,24.647,0,0,0,13.528,3.973q12.667,0,20.185-11.38a45.374,45.374,0,0,0,7.515-25.551q0-18.037-9.557-28.45T234.085,115.925Z"
            fill="#fff"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M333.931,105.19a76.64,76.64,0,0,1,11.917,1.181q7.193,1.184,9.985,1.611a124.674,124.674,0,0,1,2.576,21.9c0,.716-.933,1.072-2.791,1.072q-3.009,0-3.22-1.5a22.417,22.417,0,0,0-6.12-11.38q-5.048-5.367-11.488-5.369-15.459,0-15.459,12.668a17.25,17.25,0,0,0,.858,5.691,11.7,11.7,0,0,0,3.328,4.724q2.467,2.255,3.758,3.22a62.93,62.93,0,0,0,5.689,3.436q4.4,2.471,5.477,3.114.855.432,5.045,2.9t5.9,3.543q1.716,1.077,5.046,3.651a25.411,25.411,0,0,1,4.832,4.616,28.609,28.609,0,0,1,2.9,5.153,15.378,15.378,0,0,1,1.4,6.335,26.751,26.751,0,0,1-9.878,21.149,34.463,34.463,0,0,1-23.189,8.482,59.088,59.088,0,0,1-8.267-.537,90.373,90.373,0,0,1-8.911-1.825q-5.152-1.29-7.944-1.718a60.622,60.622,0,0,1-2.47-10.737,76.461,76.461,0,0,1-1.181-11.594,5.216,5.216,0,0,1,2.577-1.288q3.221,0,3.436,1.288,1.287,6.441,8.052,12.561t14.708,6.119a16.748,16.748,0,0,0,11.273-3.758q4.4-3.751,4.4-10.842a16.362,16.362,0,0,0-1.4-6.764,15.04,15.04,0,0,0-4.509-5.583,55.6,55.6,0,0,0-5.8-4.079q-2.688-1.61-7.622-4.188t-7.3-4.079Q302.8,144.7,302.8,131.386a22.9,22.9,0,0,1,9.34-18.9A34.378,34.378,0,0,1,333.931,105.19Z"
            fill="#fff"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M427.763,105.4q20.613,0,32.209,13.85t11.6,29.523q0,20.614-14.386,36.288T424.543,200.74q-3.221,0-5.69-.215a24.643,24.643,0,0,1-4.4-.751,31.136,31.136,0,0,1-3.006-.966q-1.077-.429-2.792-1.181t-2.147-.966a4.333,4.333,0,0,0-.858,2.791v18.466q0,12.45,1.717,19.11.429,1.5,5.584,2.9a32.764,32.764,0,0,0,7.3,1.4c.428,0,.714.892.858,2.683a7.982,7.982,0,0,1-.215,3.543q-21.473-1.077-22.974-1.073-1.29,0-23.4,1.073-.861-.861-.859-3.543c0-1.791.285-2.683.859-2.683a31.537,31.537,0,0,0,7.622-1.4q5.044-1.4,5.475-2.9a65,65,0,0,0,1.5-15.031V133.318q0-8.374-3.006-11.595a10.974,10.974,0,0,0-5.26-3.542,18.125,18.125,0,0,0-5.154-.967c-1.363,0-2.04-.141-2.04-.429q0-5.153,1.289-5.369,22.757-3.431,29.631-5.581a6.444,6.444,0,0,0,1.074-.215c.43,0,.644.395.644,1.181a24.625,24.625,0,0,1-.214,2.9c-.145,1.148-.215,1.792-.215,1.932v1.289a48.3,48.3,0,0,1,9.984-4.832A34.281,34.281,0,0,1,427.763,105.4Zm-6.871,10.52a16.632,16.632,0,0,0-10.95,3.543q-4.3,3.543-4.294,10.2V177.98q0,5.8,6.226,9.769a24.643,24.643,0,0,0,13.527,3.973q12.669,0,20.185-11.38a45.359,45.359,0,0,0,7.515-25.551q0-18.037-9.555-28.45T420.892,115.925Z"
            fill="#fff"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M534.265,105.19a44.743,44.743,0,0,1,33.067,13.957,46.062,46.062,0,0,1,13.742,33.5,47.247,47.247,0,0,1-13.42,33.819A43.781,43.781,0,0,1,534.7,200.525a44.8,44.8,0,0,1-33.282-14.063,46.648,46.648,0,0,1-13.742-33.819q0-19.968,13.312-33.711T534.265,105.19Zm-2.362,8.375q-11.383,0-18.574,10.414t-7.192,25.443q0,17.608,9.124,30.169t21.366,12.561q11.377,0,18.681-10.736t7.3-25.766q0-17.394-9.233-29.739T531.9,113.565Z"
            fill="#fff"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M606.411,118.717H593.956q-1.077,0-1.074-4.509a2.851,2.851,0,0,1,.215-1.5q6.225-2.787,13.528-11.379a57.787,57.787,0,0,0,4.616-6.335q2.254-3.543,3.758-6.12a20.154,20.154,0,0,0,1.5-2.791q6.444,0,6.443,1.074v21.257q5.58,0,16.962.107t12.024.108q1.718,0,1.718,1.932a18.291,18.291,0,0,1-2.147,8.159H622.944v52.607q0,8.589,4.4,13.742a14.387,14.387,0,0,0,11.488,5.153,26.068,26.068,0,0,0,13.312-4.079q.645-.428,1.932,1.824c.86,1.5,1.216,2.329,1.075,2.47a35.064,35.064,0,0,1-9.878,6.763,30.389,30.389,0,0,1-13.742,3.328,24.522,24.522,0,0,1-17.929-7.086q-7.2-7.084-7.192-20.183Z"
            fill="#fff"
          />
          <g id="Group_1" data-name="Group 1">
            <path
              id="Path_9"
              data-name="Path 9"
              d="M661.081,118.525v-8.432h-3.207v-1.471h8.092v1.471h-3.232v8.432Zm5.99,0v-9.9H669.5l2.5,7.91,2.418-7.91H676.8v9.9H675.3v-8.311l-2.552,8.311h-1.494l-2.66-8.432v8.432Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LogoIcon.propTypes = SVGPropTypes;

export default LogoIcon;
