/**
 *
 * Person
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const Person = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg
    height={height}
    width={width}
    style={style}
    id="icon-avatar"
    viewBox="0 0 32 32"
  >
    <title>Avatar</title>
    <path
      fill={fill}
      d="M16.168 2.88c-7.307 0-13.261 5.953-13.272 13.271-0.005 3.612 1.42 7.115 3.91 9.609 2.366 2.369 5.508 3.674 8.85 3.674 0.178 0 0.358-0.004 0.538-0.011 7.427-0.304 13.245-6.134 13.245-13.271 0-7.318-5.954-13.272-13.272-13.272zM16.142 28.144c-0.163 0.007-0.325 0.010-0.486 0.010-2.646 0-5.152-0.913-7.164-2.588 0.001-0.013 0.004-0.025 0.004-0.038 0-3.363 1.972-6.225 4.925-7.199 0.743 0.38 1.582 0.6 2.473 0.6s1.73-0.219 2.473-0.6c3.023 0.993 5.121 3.901 5.137 7.163-2.021 1.562-4.564 2.538-7.361 2.652zM15.893 17.649c-2.298 0-4.168-1.869-4.168-4.168s1.869-4.168 4.168-4.168 4.168 1.87 4.168 4.168-1.869 4.168-4.168 4.168zM24.709 24.43c-0.381-3.063-2.328-5.731-5.092-6.984 1.058-0.994 1.724-2.402 1.724-3.965 0-3.004-2.444-5.448-5.448-5.448s-5.448 2.444-5.448 5.448c0 1.564 0.666 2.972 1.725 3.967-2.685 1.233-4.524 3.849-4.884 6.958-1.988-2.217-3.116-5.19-3.111-8.253 0.010-6.613 5.389-11.993 11.992-11.993 6.612 0 11.991 5.38 11.991 11.992 0 3.208-1.308 6.124-3.451 8.278z"
    />
  </svg>
);

Person.propTypes = SVGPropTypes;

export default Person;
