/**
 *
 * AsideSlider
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import ButtonIcon from 'components/Elements/ButtonIcon';
import CloseIcon from 'components/icons/Close';
import { HEADER_HEIGHT } from 'components/Header';

import { toggleMenu } from 'actions/display';

import AsideMenu from './AsideMenu';

import Slider, { SLIDER_WIDTH } from './Slider';

export const AsideSlider = ({
  rtl = true,
  dispatch,
  menuIsOpen,
  user,
  modals,
}) => {
  const animatedProps = useSpring({
    opacity: menuIsOpen ? 1 : 0,
  });
  return (
    <Container rtl={rtl}>
      <Slider flexgrow open={menuIsOpen}>
        <animated.div style={animatedProps}>
          <ButtonWrap>
            <ButtonIcon
              onClick={() => dispatch(toggleMenu())}
              Icon={CloseIcon}
              width="30px"
            />
          </ButtonWrap>
        </animated.div>
        <AsideMenu
          handleCloseMenu={() => dispatch(toggleMenu())}
          open={menuIsOpen}
          user={user}
          modals={modals}
        />
      </Slider>
    </Container>
  );
};

AsideSlider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rtl: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  user: PropTypes.object,
  modals: PropTypes.object,
};
const mapStateToProps = ({ display, user, modals }) => ({
  menuIsOpen: display.menuIsOpen,
  user,
  modals,
});
const withConnect = connect(mapStateToProps, dispatch => ({ dispatch }));
export default compose(memo, withConnect)(AsideSlider);

const Container = styled.div`
  position: fixed;
  right: -${SLIDER_WIDTH}px;
  top: 0px;
  height: 100%;
  ${({ theme }) => theme.media.min.mobile`
    position: absolute;
    top: ${HEADER_HEIGHT}px;
    height: calc(100% - ${HEADER_HEIGHT}px);
  `}

  width: 100%;
  max-width: ${SLIDER_WIDTH}px;
  z-index: 100;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px;

  svg {
    fill: grey;
    ${({ theme }) => theme.media.min.mobile`
      opacity: 0;
    `}
    ${({ theme }) => theme.media.min.tablet`
      opacity: 1;
    `}
  }
`;
