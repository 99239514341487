// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-change-email-js": () => import("./../../../src/pages/account/change-email.js" /* webpackChunkName: "component---src-pages-account-change-email-js" */),
  "component---src-pages-account-change-password-js": () => import("./../../../src/pages/account/change-password.js" /* webpackChunkName: "component---src-pages-account-change-password-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-confirm-user-js": () => import("./../../../src/pages/confirmUser.js" /* webpackChunkName: "component---src-pages-confirm-user-js" */),
  "component---src-pages-cookies-notice-js": () => import("./../../../src/pages/cookies-notice.js" /* webpackChunkName: "component---src-pages-cookies-notice-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/passwordReset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-ripspot-[id]-js": () => import("./../../../src/pages/ripspot/[id].js" /* webpackChunkName: "component---src-pages-ripspot-[id]-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-ripspot-js": () => import("./../../../src/templates/ripspot.js" /* webpackChunkName: "component---src-templates-ripspot-js" */)
}

