/**
 *
 * AsideMenu
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { compose } from 'redux';
import { Trail, animated, config } from 'react-spring/renderprops';

import { sendCustomGaEvent } from 'utils/ga';

import Person from 'components/icons/Person';
import Globe from 'components/icons/Globe';
import LogoutIcon from 'components/icons/Logout';
import { logout } from 'actions/auth';
import { toggleMenu } from 'actions/display';
import {
  closeRegistrationModal,
  openLoginModal,
  openSignupModal,
  openOnBoardingModal,
} from 'actions/modals';

import {
  ACCOUNT_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  MAP_SEARCH_ROUTE,
  FAQ_ROUTE,
  MAP_CR_LOCATIONS_ROUTE,
} from 'utils/routes';
import useRouter from 'utils/hooks/useRouter';
import { toastSuccess } from 'utils/toastify';

/**
 *
 *
 * Routes
 *
 *
 *  */

const links = [
  { href: ACCOUNT_ROUTE, label: 'My Account' },
  { href: MAP_SEARCH_ROUTE, label: 'Search' },
  { href: '#', label: 'Create a Ripspot', createRipspot: true },
  { href: FAQ_ROUTE, label: 'FAQs' },
];

const AsideMenu = ({ handleCloseMenu, open, dispatch, user, modals }) => {
  const { location } = useRouter();

  const handleLink = href => evt => {
    evt.preventDefault();
    const { registrationModal } = modals;
    switch (href) {
      case 'logout':
        dispatch(logout());
        toastSuccess('Successfully logged out!');
        navigate('/');
        sendCustomGaEvent('Log Out', 'Logout', 'Logout');
        break;
      case LOGIN_ROUTE:
      case SIGNUP_ROUTE:
        // defined bottom of file!
        // eslint-disable-next-line no-use-before-define
        handleLoginSignupRoute({
          modal: registrationModal,
          dispatch,
          href,
          location,
        });
        sendCustomGaEvent('Sign Up', 'Started', 'Signup Began');
        break;
      default:
        navigate(href);
    }
    // Close AsideSlider menu
    handleCloseMenu();
  };

  const handleCreateRipspot = () => evt => {
    evt.preventDefault();
    dispatch(toggleMenu(true));
    if (user) {
      dispatch(openOnBoardingModal());
      navigate(MAP_CR_LOCATIONS_ROUTE, { state: { createNewRipspot: true } });
    } else {
      dispatch(openSignupModal());
      sendCustomGaEvent('Sign Up', 'Started', 'Signup Began');
    }
  };

  const items = [];

  if (user) {
    // USER IS logged in
    items.push(
      <a
        className="person-link"
        key="person-icon"
        href={ACCOUNT_ROUTE}
        onClick={handleLink(ACCOUNT_ROUTE)}
        style={{
          display: 'inline-block',
          width: '100%',
          textAlign: 'center', // needed for desktop
          marginBottom: '15px',
        }}
      >
        <IconWrapper>
          <Person width="75px" />
        </IconWrapper>
      </a>,
    );
    items.push(
      <CurrentUser key={user.firstName}>
        <h3>
          {user.firstName} {user.lastName}
        </h3>
        <h4>{user.email}</h4>
      </CurrentUser>,
    );
    items.push(
      ...links.map(({ href, label, createRipspot }) =>
        createRipspot ? (
          <a key={`_${href}`} href={href} onClick={handleCreateRipspot()}>
            {label}
          </a>
        ) : (
          <a key={`_${href}`} href={href} onClick={handleLink(href)}>
            {label}
          </a>
        ),
      ),
    );

    items.push(
      <LogoutLink
        key="logout-icon"
        href="logout"
        onClick={handleLink('logout')}
      >
        Logout
        <LogoutIcon fill="grey" width="20px" style={{ marginLeft: '15px' }} />
      </LogoutLink>,
    );
  } else {
    //  NO USER
    items.push(
      <a
        className="globe-link"
        key="globe-icon"
        href={LOGIN_ROUTE}
        onClick={handleLink(LOGIN_ROUTE)}
        style={{
          display: 'inline-block',
          width: '100%',
          textAlign: 'center',
          marginBottom: '35px',
        }}
      >
        <IconWrapper globe>
          <Globe width="75px" />
        </IconWrapper>
      </a>,
      <a
        key="home"
        className="first-link"
        href={MAP_SEARCH_ROUTE}
        onClick={handleLink(MAP_SEARCH_ROUTE)}
      >
        Search
      </a>,
      <a key="create" href="#!" onClick={handleCreateRipspot()}>
        Create A Ripspot
      </a>,
      <a key="faq" href={FAQ_ROUTE} onClick={handleLink(FAQ_ROUTE)}>
        FAQs
      </a>,
      <a key="login" href={LOGIN_ROUTE} onClick={handleLink(LOGIN_ROUTE)}>
        Sign In
      </a>,
      <a key="signup" href={SIGNUP_ROUTE} onClick={handleLink(SIGNUP_ROUTE)}>
        Signup
      </a>,
    );
  }

  const DX = 20;
  return (
    <Nav>
      <List>
        <Trail
          native
          reverse={!open}
          config={config.stiff}
          initial={null}
          items={items}
          keys={item => item.key}
          from={{ opacity: 0, x: -DX }}
          to={{ opacity: open ? 1 : 0, x: open ? 0 : DX }}
        >
          {/* eslint-disable-next-line react/prop-types */}
          {item => ({ x, opacity }) => (
            <AnimatedLi
              style={{
                opacity,
                // eslint-disable-next-line
                transform: x.interpolate(newX => `translate3d(${newX}px,0,0)`),
              }}
            >
              {item}
            </AnimatedLi>
          )}
        </Trail>
      </List>
    </Nav>
  );
};

AsideMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  open: PropTypes.bool,
  user: PropTypes.object,
  modals: PropTypes.object,
};

const withConnect = connect(null, dispatch => ({ dispatch }));
export default compose(memo, withConnect)(AsideMenu);

const Nav = styled.nav`
  ${({ theme }) => theme.styles.grow}
  position: relative;
  color: #000;
  justify-content: space-between;

  font-size: 1.2rem;

  h3 {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  svg {
    fill: #000;
    transition: fill 0.3s;
    &:hover {
      fill: lightgrey;
    }
  }
  a {
    /* color: #000; */
    color: ${({ theme: { colors } }) => colors.darkGrey};
    width: 100%;
    line-height: 2rem;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: ${({ theme: { colors } }) =>
        colors.alphaMod(colors.darkGrey, 0.75)};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  svg {
    fill: #000;
    fill: ${({ theme }) => theme.colors.Primary};

    ${({ globe, theme }) =>
      globe &&
      css`
        fill: ${theme.colors.Primary};
      `}
  }
`;

const LogoutLink = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.max.mobile`
    justify-content: center;
  `}
`;

const CurrentUser = styled.div`
  margin-bottom: 40px;
  font-size: 0.9rem;

  h3,
  h4 {
    ${({ theme }) => theme.styles.ellipsisMask};
    text-align: center;
    color: grey;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  h3 {
    color: #000;
  }
`;

const AnimatedLi = styled(animated.li)`
  font-size: 1.1rem;

  ${({ theme }) => theme.media.max.mobile`
    text-align: center;
    &:not(:first-of-type) {
      margin-top: 35px;
    }
  `}
  ${({ theme }) => theme.media.min.mobile`
    margin-left: 40px;
    &:not(:first-of-type) {
      margin-top: 15px;
      margin-top: 25px;
    }
  `}

  :nth-of-type(2) {
    margin-top: 0px; /* email and name */
  }
`;

/**
 * This handles logic of opening/closing login and signup modals
 * depending on current state. Will either open new modal or close current modal
 * to reveal already mounted page
 *
 * @param {*} { modal, dispatch, href, location }
 * @returns
 */
function handleLoginSignupRoute({ modal, dispatch, href, location }) {
  const isLogin = href.includes(LOGIN_ROUTE);
  const routeType = isLogin ? 'login' : 'signup';
  // MODAL ALREADY OPEN
  if (modal.isOpen) {
    if (modal.type !== routeType) {
      // the OTHER modal is open, close it
      dispatch(closeRegistrationModal());
      if (!location.pathname.includes(href)) {
        // if not on already on page, open modal
        isLogin ? dispatch(openLoginModal()) : dispatch(openSignupModal());
      }
    }
    return;
  }
  // MODAL NOT OPEN
  if (!location.pathname.includes(href)) {
    isLogin ? dispatch(openLoginModal()) : dispatch(openSignupModal());
  }
}
