/**
 *
 * HTTP api methods
 *
 *  These are intended only for calls to our API since
 *  each method prepends our API_URL
 *
 */

// import { checkPropTypes, PropTypes } from 'prop-types';
import { API_URL } from './urls';
import { normalizeResponseErrors, formQuery } from './utils';

let token = null;
export const initAPIClient = _token => {
  token = _token;
};

export const getToken = () => token;

const apiFetch = (url, options, resolve, reject) => {
  fetch(`${API_URL}${url}`, options)
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json())
    .then(res => resolve(res))
    .catch(err => reject(err));
};

/**
 * GET API call
 *
 * @param {object} data
 */
export const apiGET = ({ url, query = {} }) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    if (query && Object.keys(query).length) {
      // qs this and append to url
      // handles JSON ==> `?page=0&limit=30`
      const queryStringed = formQuery(query);
      url = `${url}?${queryStringed}`;
    }

    apiFetch(url, options, resolve, reject);
  });

// /**
//  * GET API call
//  *
//  * @param {object} data
//  */
// export const apiGET = props =>
//   new Promise((resolve, reject) => {
//     let useAuth = !('useAuth' in props); // default to api token
//     let url;
//     let query = {};

//     if (typeof url === 'string') {
//       url = props;
//     } else {
//       url = props;
//       query = props.query || {};
//     }

//     const options = {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // Authorization: `Bearer ${token}`,
//       },
//     };
//
//     // add our API JWT
//     if (useAuth) {
//       options.headers.Authorization = `Bearer ${token}`;
//     }

//     if (query && Object.keys(query).length) {
//
//
//
//       // qs this and append to url
//       // handles JSON ==> `?page=0&limit=30`
//       const queryStringed = formQuery(query);
//       url = `${url}?${queryStringed}`;
//     }

//

//     apiFetch(url, options, resolve, reject);
//   });

/**
 * POST API call
 *
 * @param {object} data
 */
export const apiPOST = ({ url, data, recaptchaToken, useAuth = true }) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    if (recaptchaToken) {
      // use lowercase because express transforms to lowercase by default
      options.headers.recaptchatoken = recaptchaToken;
    }

    if (useAuth) {
      // allows public req's to /api/users
      // for user registration
      options.headers.Authorization = `Bearer ${token}`;
    }

    apiFetch(url, options, resolve, reject);
  });

/**
 * PUT API call
 *
 * @param {object} data
 */
export const apiPUT = ({ url, data, useAuth = true }) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    if (useAuth) {
      // allows public req's to /api/forgotPassword/resetPassword
      options.headers.Authorization = `Bearer ${token}`;
    }
    apiFetch(url, options, resolve, reject);
  });

/**
 * DELETE API call
 *
 * @param {object} data
 */
export const apiDELETE = ({ url, data }) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    apiFetch(url, options, resolve, reject);
  });
