/* eslint-disable import/no-cycle */
/**
 * Ripspot Actions
 *
 *
 */
import { navigate } from 'gatsby';

import { openOnBoardingModal } from 'actions/modals';

import { MAP_CR_LOCATIONS_ROUTE } from 'utils/routes';
// import { getRipspots } from 'utils/api/ripspots';
import { convertToTempData, formatPins } from 'utils/helperFunctions/ripspots';
// import useRouter from 'utils/hooks/useRouter';

export const SET_EDIT_IN_PROGRESS = 'SET_EDIT_IN_PROGRESS';
export const setEditInProgress = editInProgress => ({
  type: SET_EDIT_IN_PROGRESS,
  editInProgress,
});

export const SET_VIEW_SINGLE_RIPSPOT = 'SET_VIEW_SINGLE_RIPSPOT';
export const setViewSingleRipspot = viewSingleRipspot => ({
  type: SET_VIEW_SINGLE_RIPSPOT,
  viewSingleRipspot,
});

export const SET_IS_PUBLIC_USER_VIEW = 'SET_IS_PUBLIC_USER_VIEW';
export const setisPublicUserView = isPublicUserView => ({
  type: SET_IS_PUBLIC_USER_VIEW,
  isPublicUserView,
});

export const CREATE_RIPSPOT = 'CREATE_RIPSPOT';
export const createRipspot = ({ ripspot, ripspotId }) => dispatch => {
  // tell app ripspot is currently being edited
  dispatch(setEditInProgress(true));
  // start creation process
  dispatch({
    type: CREATE_RIPSPOT,
    ripspot,
    ripspotId,
  });
};

export const SET_IS_IN_EXISTING_PIN_EDIT_MODE =
  'SET_IS_IN_EXISTING_PIN_EDIT_MODE';
export const setIsInExistingPinEditMode = isInExistingPinEditMode => ({
  type: SET_IS_IN_EXISTING_PIN_EDIT_MODE,
  isInExistingPinEditMode,
});

//
// used for saving ripspot creation data in case user leaves session
//
export const SET_TEMP_RIPSPOT = 'SET_TEMP_RIPSPOT';
export const setTempRipspot = tempRipData => ({
  type: SET_TEMP_RIPSPOT,
  tempRipData,
});

//
// Initial pins to show when map first opens
// SET_BASE_PINS
export const SET_PUBLIC_PINS = 'SET_PUBLIC_PINS';
export const setPublicPins = publicRipspotPins => ({
  type: SET_PUBLIC_PINS,
  publicRipspotPins,
});

// *
// *
// *
// *
// *
// *       NEW!!!!!!!!!
// *
// *
// *
// *

// set read only public pins
export const SET_PUBLIC_RIPSPOTS = 'SET_PUBLIC_RIPSPOTS';
export const setPublicRipspots = publicRipspots => ({
  type: SET_PUBLIC_RIPSPOTS,
  publicRipspots, // this is messed up and confusing, fixing now
});

// add read only public pins
// not sure we ever neeed this but cleaning everything up for new map flow.
export const ADD_PUBLIC_PIN = 'ADD_PUBLIC_PIN';
export const addPublicPin = publicRipspotPin => ({
  type: ADD_PUBLIC_PIN,
  publicRipspotPin, // this is messed up and confusing, fixing now
});

export const RESET_TEMP_RIPSPOT = 'RESET_TEMP_RIPSPOT';
export const resetTempRipspot = () => dispatch => {
  // tell app ripspot is currently being edited
  // dispatch(setEditInProgress(false));
  // resets all current edit data
  dispatch({
    type: RESET_TEMP_RIPSPOT,
  });
};

/**
 *
 * Recently moved to a global action so our nav in header can dispatch it!!
 *
 *  */
export const SET_IS_IN_NEW_RIPSPOT_MODE = 'SET_IS_IN_NEW_RIPSPOT_MODE';
export const setIsInNewRipspotMode = isInNewRipspotMode => ({
  type: SET_IS_IN_NEW_RIPSPOT_MODE,
  isInNewRipspotMode,
});

export const SET_BOUND_PINS = 'SET_BOUND_PINS';
export const setBoundPins = boundRipspotPins => ({
  type: SET_BOUND_PINS,
  boundRipspotPins,
});

/**
 *
 * Create Rispot Actions
 *
 *
 *  */

export const ADD_RIPSPOT_PIN = 'ADD_RIPSPOT_PIN';
export const addRipspotPin = ripspotPin => ({
  type: ADD_RIPSPOT_PIN,
  ripspotPin,
});

export const UPDATE_RIPSPOT_PIN = 'UPDATE_RIPSPOT_PIN';
export const updateRipspotPin = (
  ripspotPinId,
  pinCoords,
  pinLabel,
  pinDescription,
) => ({
  type: UPDATE_RIPSPOT_PIN,
  ripspotPinId,
  pinCoords,
  pinLabel,
  pinDescription,
});

export const UPDATE_RIPSPOT_PINS_NAME = 'UPDATE_RIPSPOT_PINS_NAME';
export const updateRipspotPinsName = pinName => ({
  type: UPDATE_RIPSPOT_PINS_NAME,
  pinName,
});

export const REMOVE_RIPSPOT_PIN = 'REMOVE_RIPSPOT_PIN';
export const removeRipspotPin = ripspotPinId => ({
  type: REMOVE_RIPSPOT_PIN,
  ripspotPinId,
});

export const RESET_RIPSPOT_PINS = 'RESET_RIPSPOT_PINS';
export const resetRipspotPins = () => ({
  type: RESET_RIPSPOT_PINS,
});

export const SET_RIPSPOT_LOADING = 'SET_RIPSPOT_LOADING';
export const setRipspotLoading = ripspotIsLoading => ({
  type: SET_RIPSPOT_LOADING,
  ripspotIsLoading,
});

export const RESET_SUBMIT_TOGGLE_RIPSPOT_CREATION =
  'RESET_SUBMIT_TOGGLE_RIPSPOT_CREATION';
export const resetSubmitToggleRipspotCreation = () => ({
  type: RESET_SUBMIT_TOGGLE_RIPSPOT_CREATION,
});

export const SUBMIT_TOGGLE_RIPSPOT_CREATION = 'SUBMIT_TOGGLE_RIPSPOT_CREATION';
export const submitToggleRipspotCreation = () => dispatch => {
  dispatch({
    type: SUBMIT_TOGGLE_RIPSPOT_CREATION,
  });

  setTimeout(() => {
    // reset the value for next ripspot, used to be local
    dispatch(resetSubmitToggleRipspotCreation());
  }, 3000);
};

export const RESET_ALL_RIPSPOT_DATA = 'RESET_ALL_RIPSPOT_DATA';
export const RESET_ALL_RIPSPOT_DATA_INIT = 'RESET_ALL_RIPSPOT_DATA_INIT';
// both used in action handler:
export const resetAllRipspotDataToggle = () => dispatch => {
  dispatch({
    type: RESET_ALL_RIPSPOT_DATA,
  });

  // remove tempData
  dispatch(resetTempRipspot());
  // remove pins on the map
  dispatch(resetRipspotPins());

  setTimeout(() => {
    // reset the value for next ripspot, used to be local
    dispatch({
      type: RESET_ALL_RIPSPOT_DATA_INIT,
    });
  }, 3000);
};

// TODO: Idea is to use a cleanup flag with a setTimeout in the action handler above to reset the value to false

/**
 * This is for cleaning up all data from ripspots in redux on logout
 *
 */
export const RESET_ALL_FOR_LOGOUT = 'RESET_ALL_FOR_LOGOUT';
export const resetAllForLogout = () => dispatch => {
  // just make sure this is also cleared
  dispatch(setIsInNewRipspotMode(false));
  // make sure map knows we are out of edit Modality
  dispatch(setEditInProgress(false));
  // remove tempData
  dispatch(resetTempRipspot());
  // remove pins on the map
  dispatch(resetRipspotPins());
  dispatch({
    type: RESET_ALL_FOR_LOGOUT,
  });
};

/**
 * Sets the current ripspot being viewed
 *
 * @param {*} ripspot
 */
export const HYDRATE_RIPSPOT = 'HYDRATE_RIPSPOT';
export const hydrateRipspot = ripspot => dispatch => {
  const { pins, id } = ripspot;
  const formattedPins = formatPins(pins, id);
  dispatch({
    type: HYDRATE_RIPSPOT,
    currentRipspot: ripspot,
    pins: formattedPins,
  });
};

/**
 * Sets the current ripspot being edited
 *
 * @param {*} ripspot
 */
export const HYDRATE_TEMP_DATA = 'HYDRATE_TEMP_DATA';
export const hydrateTempData = ripspot => dispatch => {
  const tempData = convertToTempData(ripspot);
  dispatch({
    type: HYDRATE_TEMP_DATA,
    tempData,
    currentRipspot: ripspot,
  });
};

// set read only public pins
export const REMOVE_CURRENT_RIPSPOT = 'REMOVE_CURRENT_RIPSPOT';
export const removeCurrentRipspot = () => ({
  type: REMOVE_CURRENT_RIPSPOT,
});

/**
 **
 **
 **
 **      NEW
 **
 **
 **
 **
 ** */

/**
 * This preps the map for a new ripspot creation process
 * currently only in use by Header/Footer --> will look into this
 *
 * @param {*} ripspots
 */
export const handleNewRipspots = ripspots => dispatch =>
  new Promise(resolve => {
    window.scrollTo(0, 0);

    if (Object.keys(ripspots.pins).length) {
      // remove any pins from the map to prepare for edit mode
      // TODO: save these for adding back to map later?
      dispatch(resetRipspotPins());
    }

    // remove tempData
    dispatch(resetTempRipspot());
    // set edit modes to true
    dispatch(setEditInProgress(true));
    // new redux action moved from explore map local state
    dispatch(setIsInNewRipspotMode(true));
    // Need to first open the "On-boarding step"
    dispatch(openOnBoardingModal());

    // return code execution
    resolve();
  });
