/**
 *
 * Modal Actions
 *
 *
 */
import { setEditInProgress } from './ripspots';

export const OPEN_REGISTRATION_MODAL = 'OPEN_REGISTRATION_MODAL';
export const openRegistrationModal = (isOpen, registrationType, metadata) => ({
  type: OPEN_REGISTRATION_MODAL,
  isOpen,
  registrationType,
  metadata,
});
export const CLOSE_REGISTRATION_MODAL = 'CLOSE_REGISTRATION_MODAL';
export const closeRegistrationModal = () => ({
  type: CLOSE_REGISTRATION_MODAL,
});
// actions that are dispatched
//    e.g.  dispatch(myAction())
// can option return (dispatch, getState)
// before as a "middleman" function
export const openLoginModal = (isOpen = true, metadata = {}) => dispatch => {
  dispatch(openRegistrationModal(isOpen, 'login', metadata));
};
export const openSignupModal = (isOpen = true, metadata = {}) => dispatch => {
  dispatch(openRegistrationModal(isOpen, 'signup', metadata));
};

export const OPEN_RIPSPOT_MODAL = 'OPEN_RIPSPOT_MODAL';
export const openRipspotModal = ({ metadata = {} }) => ({
  type: OPEN_RIPSPOT_MODAL,
  metadata,
});

export const CLOSE_RIPSPOT_MODAL = 'CLOSE_RIPSPOT_MODAL';
export const closeRipspotModal = () => ({
  type: CLOSE_RIPSPOT_MODAL,
});

export const SET_RIPSPOT_CURRENT_STEP = 'SET_RIPSPOT_CURRENT_STEP';
export const setRipspotCurrentStep = step => ({
  type: SET_RIPSPOT_CURRENT_STEP,
  payload: step,
});

export const SET_RIPSPOT_PREV_STEP = 'SET_RIPSPOT_PREV_STEP';
export const setRipspotPrevStep = step => ({
  type: SET_RIPSPOT_PREV_STEP,
  payload: step,
});

// *
// * Memorial Modal, for when we click on Pins on the map and the memorial page
// * content is opened inside a modal over the Explore Map
// *
export const OPEN_RIPSPOT_MEMORIAL_MODAL = 'OPEN_RIPSPOT_MEMORIAL_MODAL';
export const openRipspotMemorialModal = ({ metadata = {}, ripspotId }) => ({
  type: OPEN_RIPSPOT_MEMORIAL_MODAL,
  metadata,
  ripspotId,
});

export const CLOSE_RIPSPOT_MEMORIAL_MODAL = 'CLOSE_RIPSPOT_MEMORIAL_MODAL';
export const closeRipspotMemorialModal = () => ({
  type: CLOSE_RIPSPOT_MEMORIAL_MODAL,
});
// *
// *
// *

export const OPEN_FORGOT_PASSWORD_MODAL = 'OPEN_FORGOT_PASSWORD_MODAL';
export const openForgotPasswordModal = () => ({
  type: OPEN_FORGOT_PASSWORD_MODAL,
});
export const CLOSE_FORGOT_PASSWORD_MODAL = 'CLOSE_FORGOT_PASSWORD_MODAL';
export const closeForgotPasswordModal = () => ({
  type: CLOSE_FORGOT_PASSWORD_MODAL,
});
export const OPEN_RESET_PASSWORD_MODAL = 'OPEN_RESET_PASSWORD_MODAL';
export const openResetPasswordModal = () => ({
  type: OPEN_RESET_PASSWORD_MODAL,
});
export const CLOSE_RESET_PASSWORD_MODAL = 'CLOSE_RESET_PASSWORD_MODAL';
export const closeResetPasswordModal = () => ({
  type: CLOSE_RESET_PASSWORD_MODAL,
});
export const OPEN_ON_BOARDING_MODAL = 'OPEN_ON_BOARDING_MODAL';
export const openOnBoardingModal = () => dispatch => {
  // tell app ripspot is currently being edited
  dispatch(setEditInProgress(true));
  dispatch({
    type: OPEN_ON_BOARDING_MODAL,
  });
};
export const CLOSE_ON_BOARDING_MODAL = 'CLOSE_ON_BOARDING_MODAL';
export const closeOnBoardingModal = () => ({
  type: CLOSE_ON_BOARDING_MODAL,
});

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// ! There are two "challenge modals" --> one spelled correctly is hardcoded to work with ripspots, action with 3 L's is truly a dynamic modal to be droped in anywhere and used
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

/**
 *
 *
 *  * Challenge Modal Actions
 *
 *  * I dont think this is in use!!!!
 *
 * */
export const CLOSE_CHALLENGE_MODAL = 'CLOSE_CHALLENGE_MODAL';
export const closeChallengeModal = () => ({
  type: CLOSE_CHALLENGE_MODAL,
  isOpen: false,
});

export const OPEN_CHALLENGE_MODAL = 'OPEN_CHALLENGE_MODAL';
export const openChallengeModal = data => dispatch => {
  const { onConfirm, metadata } = data;

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  dispatch({
    type: OPEN_CHALLENGE_MODAL,
    isOpen: true,
    onConfirm,
    metadata, // could be ripspot inour case, leaving for future use in custom callbacks with custom prompts etc
    onRequestClose: () => dispatch(closeChallengeModal()), // close the modal
    // TODO: callbacks: [] // dyanmic functions but idk how these would fire without a UI integration
  });
};
/** -------------------------------------------------------------------------------------------------- */
// /////////
//
//
// Using this as new one to move from fixed above to real dyanmic one
//
//
// //////////

export const CLOSE_CHALLLENGE_MODAL = 'CLOSE_CHALLLENGE_MODAL';
export const closeChalllengeModal = () => ({
  type: CLOSE_CHALLLENGE_MODAL,
  isOpen: false,
});

export const OPEN_CHALLLENGE_MODAL = 'OPEN_CHALLLENGE_MODAL';
export const openChalllengeModal = data => dispatch => {
  const { onConfirm, metadata } = data;

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  dispatch({
    type: OPEN_CHALLLENGE_MODAL,
    isOpen: true,
    onConfirm,
    metadata, // could be ripspot inour case, leaving for future use in custom callbacks with custom prompts etc
    onRequestClose: () => dispatch(closeChalllengeModal()), // close the modal
    // TODO: callbacks: [] // dyanmic functions but idk how these would fire without a UI integration
  });
};

// TODO: future dynamic challenge modal that accepts prompts
export const RESET_CHALLENGE_MODAL = 'RESET_CHALLENGE_MODAL';
export const resetChallengeModal = () => ({
  type: RESET_CHALLENGE_MODAL,
  prompt: '',
  metadata: {},
  onConfirm: () => false,
  // TODO? needed? onRequestClose: () => dispatch(closeChallengeModal()), // close the modal
});

export const OPEN_ADDITIONAL_PIN_MODAL = 'OPEN_ADDITIONAL_PIN_MODAL';
export const openAdditionalPinModal = () => ({
  type: OPEN_ADDITIONAL_PIN_MODAL,
});
export const CLOSE_ADDITIONAL_PIN_MODAL = 'CLOSE_ADDITIONAL_PIN_MODAL';
export const closeAdditionalPinModal = () => ({
  type: CLOSE_ADDITIONAL_PIN_MODAL,
});
