import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const Address = () => (
  <Container>
    <p>&copy; 2022 Ripspot, LLC</p>
    <p>PO Box 4262</p>
    <p>Portland, ME 04101</p>
  </Container>
);

Address.propTypes = {};

export default Address;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 2em 0;
  ${({ theme }) => theme.media.min.mobile`
    width: 100%;
    margin-top: 25px;
  `}

  @media all and (min-width: 800px) {
    margin: 1em 0;
  }
`;
