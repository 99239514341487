/**
 *
 * URL's for api's
 *
 *
 */

export const PROD_BASE_API = 'https://api.ripspot.com';
export const DEV_BASE_API = 'https://develop.api.ripspot.com';
export const API_FUNC_SLUG = '/.netlify/functions/serverless';
// export const API_FUNC_SLUG = '/develop.api.ripspot.com';

export const LAMBDA_LOCAL = `http://localhost:4202${API_FUNC_SLUG}`;
export const LAMBDA_DEV = `${DEV_BASE_API}${API_FUNC_SLUG}`;
export const LAMBDA_PROD = `${PROD_BASE_API}${API_FUNC_SLUG}`;

const GATSBY_ENV_LAMDA = process.env.GATSBY_LAMBDA;

// 'https://develop--ripspot-api-v1.netlify.com/.netlify/functions/serverless';
// 'https://ripspot-api-v1.netlify.com/.netlify/functions/serverless';
export const routeMapping = {
  production: LAMBDA_PROD,
  development: LAMBDA_DEV,
  local: LAMBDA_LOCAL,
};

//
// Select base API endpoint here
//
const API_URL_BASE = routeMapping[GATSBY_ENV_LAMDA]; // select correct settings  --> env vars depending on build setting

// LAMBDA flag takes precdencex
// otherwise, NODE_ENV dictates lambda url
export const API_URL = API_URL_BASE;

// AUTH
export const LOGIN = '/api/auth/login';
export const REFRESH_TOKEN = '/api/auth/refresh';

// Users
export const USERS = '/api/users';
export const RESET_USER_PASSWORD = '/api/forgotPassword/resetPassword';
export const FOROGT_PASSWORD = '/api/forgotPassword';

export const getValidateResetTokenURL = tokenId =>
  `/api/resetTokens/${tokenId}/validate`;

// Ripspots
export const RIPSPOTS = '/api/ripspots';
export const PUBLIC_RIPSPOTS = '/api/ripspots/public';

// Stripe Payments
export const PAYMENTS = '/api/payments';
export const PAYMENT_INTENT = '/api/payment-intent';
// Stripe Subscriptions
export const CREATE_SUBSCRIPTION = '/api/create-subscription';
export const SUBSCRIPTIONS = '/api/subscriptions';

export const IMAGES = '/api/images';

/** Comments */
export const COMMENTS = '/api/comments';
