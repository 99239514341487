/**
 *
 * Layout
 *
 *
 */

import React, { memo, useLayoutEffect, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled, {
  ThemeProvider,
  css,
  createGlobalStyle,
} from 'styled-components';
import PropTypes from 'prop-types';

import Header, { HEADER_HEIGHT } from 'components/Header';
import Footer from 'components/Footer';
import AsideNav from 'containers/AsideSlider';
import LoginModal from 'components/modals/Registration/LoadableLogin';
import SignupModal from 'components/modals/Registration/LoadableSignup';
import ForgotPasswordModal from 'components/modals/ForgotPasswordModal/Loadable';
import ResetPasswordModal from 'components/modals/ResetPasswordModal/Loadable';
import ChallengeModal from 'components/modals/ChallengeModal/Loadable';
import SEO from 'components/seo';

import {
  setDimensions,
  setScrollTop,
  setIsTouchDevice,
  setIsHoverDevice,
} from 'actions/display';
import { openSignupModal } from 'actions/modals';

import Theme from 'utils/theme';
import { listenForTouch, listenForHover } from 'utils/helperFunctions/device';
import { useEventListenerDocumentBody } from 'utils/hooks/useEventListener';
import useRouter from 'utils/hooks/useRouter';
// import useResize from 'utils/hooks/useResize';
import useScrollTop from 'utils/hooks/useScrollTop';
import {
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  ACCOUNT_ROUTE,
  TERMS_ROUTE,
  FAQ_ROUTE,
  CHANGE_EMAIL_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  MAP_SEARCH_ROUTE,
} from 'utils/routes';
import { hasUserAlreadySignedUpOrSeenSignup } from 'utils/localStorage';

import useClickCountState from './useClickCountState';
// import useClickCountLogic from './useClickCountLogic';

import '../styles/screen.scss';

// *
// * Number of clicks per session until a user is asked to signup. Once seen,
// * they wont see it again until they close the tab/winsdow and open a new
// * session and click around 3 times....can be changed but requires medium refactor
// *
const CLICK_COUNT = 3;
// *
// *
// *
// *
//
// these dont allow the signup to show up from clicks
//
const noClickCounterRoutes = [
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  ACCOUNT_ROUTE,
  TERMS_ROUTE,
  FAQ_ROUTE,
  CHANGE_EMAIL_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
];
// *
// *
// *
// *
// *

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100vw;

    ${({ isTouchDevice }) =>
      isTouchDevice &&
      css`
        overflow-x: hidden;
      `}

    ${({ pathname }) =>
      pathname === MAP_SEARCH_ROUTE &&
      css`
        height: 100%;
      `}
  }

  body {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    /* overflow-x: hidden; */
  }

  div[role="group"][tabindex] {
    height: 100%;
  }

  ${({ isMobileMap }) =>
    isMobileMap &&
    css`
      body {
        overflow: hidden;
      }
      .mapboxgl-canvas {
        width: 100% !important;
      }
      #footer {
        display: none;
      }
      #main-container {
        ${({ theme }) => theme.media.max.mobile`
          min-height: auto !important;
        `}
      }
    `}
`;

const Modals = () => (
  <>
    <LoginModal key="login-modal" />
    <SignupModal key="signup-modal" />
    <ForgotPasswordModal key="forgot-password-modal" />
    <ResetPasswordModal key="reset-password-modal" />
    <ChallengeModal key="challenge-modal" />
    {/* <CustomChallengeModal key="real-challlenge-modal" /> */}
  </>
);

const Layout = ({
  children,
  dispatch,
  title = 'This is the default title',
  user,
  isTouchDevice,
  editInProgress,
}) => {
  const { location, ...rest } = useRouter();

  // const scrollHandler = evt => {
  //   const _scrollTop =
  //     document.body.scrollTop ||
  //     document.scrollingElement.scrollTop ||
  //     document.documentElement.scrollTop;
  //   dispatch(setScrollTop({ scrollTop: _scrollTop }));
  // };

  useLayoutEffect(() => {
    const { innerWidth, innerHeight } = window;
    dispatch(setDimensions({ width: innerWidth, height: innerHeight }));
    // scrollHandler({});
  }, []);

  const { pathname } = location;
  const isHome = pathname === '/';

  const lowerCasePathname = (pathname && pathname.toLowerCase()) || '';

  // useEventListenerDocumentBody('scroll', scrollHandler);

  useEffect(() => {
    listenForTouch(() => {
      dispatch(setIsTouchDevice(true));
    });
    listenForHover(() => {
      dispatch(setIsHoverDevice(true));
    });
  }, []);

  // issue with Landing page versus Explore map scroll events.
  // Landing page, window event fires
  // Explore page, document.body event fires
  // const scrollTop = useScrollTop();
  // useEffect(() => {
  //   dispatch(setScrollTop({ scrollTop }));
  // }, [scrollTop]);

  const isExploreMap =
    location.pathname.toLowerCase().includes(MAP_SEARCH_ROUTE) &&
    isTouchDevice &&
    editInProgress;

  const recaptchaKey = process.env.GATSBY_RECAPTCHAKEY;

  return (
    <ThemeProvider theme={Theme}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <LayoutContainer>
          <Header />
          <Modals />
          <AsideNav />
          <Main
            id="main-container"
            role="main"
            className="DESKTOP"
            isExploreMap={isExploreMap}
          >
            <Container isExploreMap={isExploreMap}>{children}</Container>
          </Main>
          <Footer />
          <GlobalStyle
            pathname={location.pathname}
            isMobileMap={isExploreMap}
            isTouchDevice={isTouchDevice}
          />
        </LayoutContainer>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

const withConnect = connect(
  ({ modals, user, display, ripspots }) => ({
    ChallenegeModal: modals.ChallenegModal,
    user,
    isTouchDevice: display.isTouchDevice,
    // isViewingSinglePin: ripspots.viewSingleRipspot,
    editInProgress: ripspots.editInProgress,
  }),
  dispatch => ({ dispatch }),
);

export default compose(memo, withConnect)(Layout);

const LayoutContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

// const MainMobile = styled.main`
//   color: #fff;
//   display: flex;
//   flex-direction: column;
// `;

const Main = styled.main`
  display: flex;
  flex-direction: column;


  ${({ isExploreMap }) =>
    isExploreMap &&
    css`
      background-color: ${({ theme }) => theme.colors.Primary};
    `}

  ${({ isExploreMap }) =>
    !isExploreMap &&
    css`
      min-height: calc(100vh - ${HEADER_HEIGHT}px);
    `}
    

  ${({ theme, isExploreMap }) =>
    isExploreMap &&
    theme.media.min.mobile`
        min-height: calc(100vh - ${HEADER_HEIGHT}px);
  `}

  ${({ theme, isExploreMap }) =>
    isExploreMap &&
    theme.media.max.mobile`
      min-height: auto;
  `}

`;

const Container = styled.div`
  ${({ theme, isExploreMap }) =>
    isExploreMap
      ? css`
          background-color: ${theme.colors.Primary};
        `
      : css`
          background-color: ${theme.colors.offWhite};
        `}

  /** Trying this Primary for bg color out so when we select a pin there is no white space issue during onboarding */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Comingsoon = styled.main``;
