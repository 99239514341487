/**
 *
 * Custom Link Element component
 *
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Theme from 'utils/theme';

const { colors } = Theme;

const CustomLink = ({
  to,
  href,
  children,
  primary,
  secondary,
  dangerous,
  dangerousBtn,
  ...rest
}) => {
  if (href) {
    // normal, external link
    return (
      <StyledExternalLink
        href={href}
        primary={primary ? 1 : 0}
        secondary={secondary ? 1 : 0}
        dangerousbtn={dangerousBtn ? 1 : 0}
        dangerous={dangerous ? 1 : 0}
        {...rest}
      >
        {children}
      </StyledExternalLink>
    );
  }

  // internal router link
  return (
    <StyledLink
      to={to}
      primary={primary ? 1 : 0}
      secondary={secondary ? 1 : 0}
      dangerousbtn={dangerousBtn ? 1 : 0}
      dangerous={dangerous ? 1 : 0}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  dangerous: PropTypes.bool,
  dangerousBtn: PropTypes.bool,
  children: PropTypes.any,
};

export default memo(CustomLink);

const LinkBaseStyles = css`
  color: ${colors.Primary};
  transition: color 0.3s;
  &:hover {
    color: ${colors.midGreen};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${colors.Primary};
      text-decoration: underline;
      margin-left: 10px;
      &:hover {
        color: ${colors.alphaMod(colors.Primary, 0.75)};
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      color: ${colors.darkGrey};
      text-decoration: underline;
      margin-left: 10px;
      &:hover {
        color: ${colors.alphaMod(colors.darkGrey, 0.75)};
      }
    `}



  ${({ dangerous }) =>
    dangerous &&
    css`
      color: ${colors.Red};
      font-size: 18px;
      &:hover {
        color: ${colors.alphaMod(colors.Red, 0.75)};
      }
      &:active {
        color: ${colors.alphaMod(colors.Red, 0.95)};
      }
    `}

  ${({ dangerousbtn }) =>
    dangerousbtn &&
    css`
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
      padding: 10px 15px;
      border-radius: 5px;
      transition: box-shadow 0.3s, background-color 0.3s;
      font-size: 18px;
      cursor: pointer;
      color: #fff;
      background-color: ${colors.Red};
      &:hover {
        color: #fff;
        background-color: ${colors.alphaMod(colors.Red, 0.75)};
      }
      &:active {
        background-color: ${colors.alphaMod(colors.Red, 0.95)};
      }
    `}
`;

const StyledExternalLink = styled.a`
  ${LinkBaseStyles}
`;

const StyledLink = styled(Link)`
  ${LinkBaseStyles}
`;
