/**
 *
 * PropTypes
 *
 *
 */

import PropTypes from 'prop-types';

export const SizeTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const SVGPropTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: SizeTypes,
};

export const ViewportPropTypes = PropTypes.shape({
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
});

export const MapStatePropTypes = PropTypes.shape({
  style: PropTypes.string.isRequired,
  viewport: ViewportPropTypes,
});
