/**
 *
 * Ripspot Utilities functions
 *
 *
 */

/**
 * Converts pin array to object keyed by pinId
 *
 * @param {[]} pins
 * @param {string} ripspotId
 * @returns
 */
export const formatPins = (pins, ripspotId) =>
  pins.reduce(
    (acc, pin) => ({
      ...acc,
      [pin.id]: {
        ...pin,
        ripspotId,
      },
    }),
    {},
  );

/**
 * Takes object of pins and returns array if pins
 *
 * @param {*} pins
 */
export const formatPinsToArray = pins =>
  Object.keys(pins).map(key => {
    const pin = pins[key];
    const { id, pinCoords, pinName, pinLabel, pinDescription } = pin;
    return {
      id,
      pinCoords,
      pinName,
      pinLabel,
      pinDescription,
    };
  });

/**
 * object keyed by `_${index}`
 *
 * @param {*} photoGallery
 */
const convertPhotoGalleryToTempDataFormat = photoGallery => {
  //
  if (!photoGallery || photoGallery.length === 0) return {};

  const formattedGallery = photoGallery.reduce((acc, item, index) => {
    return {
      ...acc,
      [`_${index}`]: {
        index,
        isProfile: false, // TODO, need to integrate in the BE on our Image model apis
        photoText: item.description,
        imageId: item._id,
        data: {
          imageSrc: item.url,
        },
      },
    };
  }, {});
  //

  //
  return formattedGallery;
};

/**
 * Converts ripspot server response to our create/edit tempData shape
 *
 * @param {object} ripspot
 * @returns
 */
export const convertToTempData = ripspot => {
  const {
    biography,
    pins,
    birthDate,
    deathDate,
    name,
    id,
    photoGallery,
  } = ripspot;

  const editor = biography;

  return {
    details: {
      birthDate,
      deathDate,
      name,
    },
    bio: editor,
    pins: formatPins(pins, id),
    //
    payment: {},
    photoGallery: convertPhotoGalleryToTempDataFormat(photoGallery), // defined above
    review: {},
    share: {},
  };
};

export const getBioMarkup = bio => {
  //

  //

  //

  //
  return bio;
};

export const populateRipspotBioMarkup = ripspots => {
  return ripspots.map(ripspot => ({
    ...ripspot,
    markup: getBioMarkup(ripspot.biography),
  }));
};
