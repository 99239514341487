export const sendCustomGaEvent = (category, action, label, value) => {
  // send custom event to Gtag
  typeof window !== 'undefined' &&
    window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value || null,
    });
};
