/**
 *
 * Mobile breakpoint Component wrapper
 *
 *
 */

import styled from 'styled-components';

export const Mobile = styled.div`
  /* was min.tablet */
  ${({ theme }) => theme.media.min.mobile`
    display: none;
  `}
`;

export default Mobile;
