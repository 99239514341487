/**
 * Tools to help with dealing with color values
 * (hex ==> rgba)
 *
 *
 */

const cache = {};

export function hex2rgb(hex, opacity) {
  if (typeof hex !== 'string') return;
  const key = `${hex}_${opacity}`;
  // store value for future requests
  // helps with re-renders performance
  if (key in cache) {
    return cache[key];
  }

  let h = hex.replace('#', '');
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'));

  for (let i = 0; i < h.length; i++) {
    h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
  }

  if (typeof opacity !== 'undefined') h.push(opacity);

  const rgbaVal = `rgba(${h.join(',')})`;
  // save for future use
  cache[key] = rgbaVal;

  return rgbaVal;
}
