/**
 *
 * CloseIcon
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

export const CloseIcon = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <title>Close Icon</title>
    <path
      fill={fill}
      d="M17.41 16l8.29-8.29a1 1 0 0 0-1.41-1.41L16 14.59l-8.29-8.3a1 1 0 0 0-1.42 1.42l8.3 8.29-8.3 8.29A1 1 0 1 0 7.7 25.7l8.3-8.29 8.29 8.29a1 1 0 0 0 1.41-1.41z"
    />
  </svg>
);

export const CloseAltIcon = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M511.987 83.318L428.726.041l-172.71 172.712L83.29.009.013 83.288l172.742 172.743L.013 428.713 83.29 511.99l172.726-172.711 172.71 172.68 83.261-83.246-172.71-172.682z"
    />
  </svg>
);

CloseIcon.propTypes = SVGPropTypes;
CloseAltIcon.propTypes = SVGPropTypes;

export default CloseIcon;
