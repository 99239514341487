import {
  CREATE_RIPSPOT, // === AddRipspot
  SET_TEMP_RIPSPOT,
  RESET_TEMP_RIPSPOT,
  ADD_RIPSPOT_PIN,
  REMOVE_RIPSPOT_PIN,
  RESET_RIPSPOT_PINS,
  UPDATE_RIPSPOT_PIN,
  HYDRATE_RIPSPOT,
  HYDRATE_TEMP_DATA,
  SET_IS_IN_EXISTING_PIN_EDIT_MODE,
  UPDATE_RIPSPOT_PINS_NAME,
  SET_EDIT_IN_PROGRESS,
  SET_VIEW_SINGLE_RIPSPOT,
  SET_IS_PUBLIC_USER_VIEW,
  // UPDATE_RIPSPOT, // TODO: if a person edits ripspot and saves..it will update db but not locally, neeed to implement this to up[date local ripspot wioth new data or make an api call and just set it over current prop
  SET_PUBLIC_RIPSPOTS,
  ADD_PUBLIC_PIN,
  SET_PUBLIC_PINS,
  SET_IS_IN_NEW_RIPSPOT_MODE,
  SET_BOUND_PINS,
  SUBMIT_TOGGLE_RIPSPOT_CREATION,
  RESET_SUBMIT_TOGGLE_RIPSPOT_CREATION,
  SET_RIPSPOT_LOADING,
  //
  REMOVE_CURRENT_RIPSPOT,
  RESET_ALL_RIPSPOT_DATA, // toggle
  RESET_ALL_RIPSPOT_DATA_INIT, // toggle init value
} from 'actions/ripspots';

const initialState = {
  tempData: {},
  ripspots: {}, // public ripspots
  currentRipspot: null, // should only be when editing but we track mode using prop, `editInProgress`
  pins: {},
  publicRipspotPins: {}, // randomized per page view aka new data fetching
  publicRipspots: {}, // randomized per page view aka new data fetching
  //
  // all come from route change state
  //
  isInExistingPinEditMode: false,
  editInProgress: false,
  viewSingleRipspot: false,
  isPublicUserView: false,
  isInNewRipspotMode: false,
  // * NEW
  submitToggle: false,
  ripspotIsLoading: false, // used to show loader now that we changed step order and loader needs to be in Payment step component
  resetAllData: false, // flag used for other components to set so we can clear data from different places in app
};

export default function ripspotsReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case CREATE_RIPSPOT:
      return {
        ...state,
        ripspots: {
          ...state.ripspots,
          [action.ripspotId]: action.ripspot,
        },
      };
    // * NEW \/
    case SUBMIT_TOGGLE_RIPSPOT_CREATION:
      return {
        ...state,
        submitToggle: true,
      };
    case RESET_SUBMIT_TOGGLE_RIPSPOT_CREATION:
      return {
        ...state,
        submitToggle: false,
      };
    case SET_RIPSPOT_LOADING:
      return {
        ...state,
        ripspotIsLoading: action.ripspotIsLoading,
      };
    case RESET_ALL_RIPSPOT_DATA:
      return {
        ...state,
        resetAllData: true,
      };
    case RESET_ALL_RIPSPOT_DATA_INIT:
      return {
        ...state,
        resetAllData: false,
      };
    // *    /\
    case HYDRATE_RIPSPOT:
      return {
        ...state,
        pins: action.pins,
        currentRipspot: action.currentRipspot,
      };
    case REMOVE_CURRENT_RIPSPOT:
      return {
        ...state,
        pins: [],
        currentRipspot: null,
      };
    case SET_IS_IN_EXISTING_PIN_EDIT_MODE:
      return {
        ...state,
        isInExistingPinEditMode: action.isInExistingPinEditMode,
      };
    case SET_IS_IN_NEW_RIPSPOT_MODE:
      return {
        ...state,
        isInNewRipspotMode: action.isInNewRipspotMode,
      };
    case SET_EDIT_IN_PROGRESS:
      return {
        ...state,
        editInProgress: action.editInProgress,
      };
    case SET_VIEW_SINGLE_RIPSPOT:
      return {
        ...state,
        viewSingleRipspot: action.viewSingleRipspot,
      };
    case SET_IS_PUBLIC_USER_VIEW:
      return {
        ...state,
        isPublicUserView: action.isPublicUserView,
      };
    case HYDRATE_TEMP_DATA:
      return {
        ...state,
        tempData: action.tempData,
        currentRipspot: action.currentRipspot,
      };
    case SET_TEMP_RIPSPOT:
      return {
        ...state,
        tempData: action.tempRipData,
      };
    case RESET_TEMP_RIPSPOT:
      return {
        ...state,
        tempData: {},
      };
    case ADD_RIPSPOT_PIN:
      return {
        ...state,
        pins: {
          ...state.pins,
          [action.ripspotPin.id]: action.ripspotPin,
        },
      };

    case UPDATE_RIPSPOT_PIN:
      return {
        ...state,
        pins: {
          ...state.pins,
          [action.ripspotPinId]: {
            ...state.pins[action.ripspotPinId],
            pinCoords: action.pinCoords,
            pinLabel: action.pinLabel,
            pinDescription: action.pinDescription,
          },
        },
      };
    case UPDATE_RIPSPOT_PINS_NAME:
      const newPins = { ...state.pins };
      const updatedPinsObject = Object.keys(newPins).reduce(
        (acc, pinKey) => ({
          ...acc,
          [pinKey]: {
            ...newPins[pinKey],
            pinName: action.pinName,
          },
        }),
        {},
      );
      return {
        ...state,
        pins: updatedPinsObject,
      };
    case REMOVE_RIPSPOT_PIN:
      const { [action.ripspotPinId]: removed, ...rest } = state.pins;
      return {
        ...state,
        pins: rest,
      };
    case SET_PUBLIC_RIPSPOTS:
      return {
        ...state,
        publicRipspots: action.publicRipspots,
      };
    //
    // Public Ripspots/Pins
    //
    case SET_PUBLIC_PINS:
      return {
        ...state,
        publicRipspotPins: action.publicRipspotPins,
      };
    case ADD_PUBLIC_PIN:
      return {
        ...state,
        publicRipspotPins: {
          ...state.publicRipspotPins,
          [action.publicRipspotPin.id]: action.publicRipspotPin,
        },
      };
    case RESET_RIPSPOT_PINS:
      return {
        ...state,
        pins: {},
      };
    case SET_BOUND_PINS:
      return {
        ...state,
        boundRipspotPins: action.boundRipspotPins,
      };
    default:
      return state;
  }
}
