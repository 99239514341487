/* eslint-disable react/jsx-no-target-blank */
/**
 *
 * Footer
 *
 *
 */

import React, { memo } from 'react';
import styled from 'styled-components';
import Link from 'components/Elements/Link';

import WhiteGreenPin from 'components/icons/Ripspot/whiteGreenPin';
import SocialMediaIcons from 'components/SocialMediaIcons';
import { MAP_SEARCH_ROUTE } from 'utils/routes';

import Address from './Address';

export const FOOTER_HEIGHT = 300;

const linksLeftColumn = [
  {
    name: 'Search',
    href: MAP_SEARCH_ROUTE,
  },
  {
    name: 'Help',
    href: '/help',
  },
  {
    name: 'Account',
    href: '/account',
  },
  {
    name: 'Create Account',
    href: '/register',
  },
];
const linksRightColumn = [
  {
    name: 'Terms & Conditions',
    href: '/terms-and-conditions',
  },
  {
    name: 'Privacy Notice',
    href: '/privacy-notice',
  },
  {
    name: 'Cookies Notice',
    href: '/cookies-notice',
  },
  {
    name: 'Support',
    href: 'mailto:support@ripspot.com',
    hours: 'Mon - Fri (9am - 5pm EST)',
  },
];

const Footer = () => {
  return (
    <StyledFooter id={'footer'}>
      <Content>
        <List>
          {linksLeftColumn.map(link => (
            <li key={link.href}>
              <Link to={link.href}>{link.name}</Link>
            </li>
          ))}
        </List>
        <List>
          {linksRightColumn.map(link => (
            <li key={link.href}>
              {link.href.includes('mailto') ? (
                <a href={link.href} target="_blank">
                  {link.name}
                </a>
              ) : (
                <Link to={link.href}>{link.name}</Link>
              )}
              {link.hours && <span>Hours: {link.hours}</span>}
            </li>
          ))}
        </List>
        <Info>
          <WhiteGreenPin />
          <SocialMediaIcons />
          <Address />
        </Info>
        <div className="photo-container">
          <img src="/images/1-percent.png" alt="" />
        </div>
      </Content>
    </StyledFooter>
  );
};
Footer.propTypes = {};

Footer.defaultProps = {};

export default memo(Footer);

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.Primary};
  position: relative;
  a {
    color: #fff;
    font-size: 1rem;
    text-decoration: underline;
    transition: color 0.3s;
    &:hover {
      color: #ddd;
    }
  }

  p {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 15px;
  @media all and (min-width: 800px) {
    flex-direction: row;
    padding-top: 6em;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .photo-container {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      max-width: 150px;
      align-self: center;
    }
    @media all and (min-width: 800px) {
      margin-top: 3em;
      order: 4;
      display: flex;
      justify-content: center;
      img {
        max-width: 175px;
      }
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  > svg {
    width: 100%;
    height: auto;
    max-width: 200px;
    margin: 2em 0;
  }
  @media all and (min-width: 800px) {
    order: 1;
    > svg {
      margin: 0;
      margin-bottom: 1m;
    }
  }
`;

const List = styled.ul`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.media.min.mobile`
    padding-left: 5%;
  `}
  ${({ theme }) => theme.media.min.tablet`
    padding-left: 20%;
  `}
  > li {
    margin-bottom: 1.5em;
  }
  li {
    display: flex;
    flex-direction: column;
    > span {
      margin-top: 0.5em;
      font-style: italic;
    }
  }
  @media all and (min-width: 800px) {
    order: 2;
  }
`;
