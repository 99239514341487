/**
 * Display Actions
 *
 *
 */

export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const setDimensions = ({ width, height }) => ({
  type: SET_DIMENSIONS,
  width,
  height,
});

export const SET_SCROLL_TOP = 'SET_SCROLL_TOP';
export const setScrollTop = ({ scrollTop }) => ({
  type: SET_SCROLL_TOP,
  scrollTop,
});

export const OPEN_MENU = 'OPEN_MENU';
export const openMenu = (isOpen = true) => ({
  type: OPEN_MENU,
  isOpen,
});

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const toggleMenu = () => ({
  type: TOGGLE_MENU,
});

export const SET_IS_TOUCH_DEVICE = 'SET_IS_TOUCH_DEVICE';
export const setIsTouchDevice = isTouchDevice => ({
  type: SET_IS_TOUCH_DEVICE,
  isTouchDevice,
});

export const SET_IS_HOVER_DEVICE = 'SET_IS_HOVER_DEVICE';
export const setIsHoverDevice = isHoverDevice => ({
  type: SET_IS_HOVER_DEVICE,
  isHoverDevice,
});
