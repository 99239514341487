/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * Header
 *
 *
 */

import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { navigate as reachNavigate } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';
import LogoIcon from 'components/icons/Logo';
import BurgerMenu from 'components/BurgerMenu';
import Mobile from 'components/utils/Mobile';
import Desktop from 'components/utils/Desktop';

import { sendCustomGaEvent } from 'utils/ga';

// import { logout } from 'actions/auth';
import {
  openLoginModal,
  openSignupModal,
  closeRipspotMemorialModal,
  // openRipspotModal,
  // openOnBoardingModal,
  // closeOnBoardingModal,
} from 'actions/modals';

import { toggleMenu } from 'actions/display';

import {
  // setEditInProgress,
  // resetTempRipspot,
  // resetRipspotPins,
  // setIsInNewRipspotMode,
  handleNewRipspots,
} from 'actions/ripspots';

import useRouter from 'utils/hooks/useRouter';
import {
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  MAP_SEARCH_ROUTE,
  MAP_CR_LOCATIONS_ROUTE,
} from 'utils/routes';
// import { toastSuccess, toastError, toastWarn, toastInfo } from 'utils/toastify';

export const HEADER_HEIGHT = 55;

const Header = ({ user, dispatch, ripspots }) => {
  const { location } = useRouter();
  // const handleLogout = async evt => {
  //   evt.preventDefault();
  //   dispatch(logout());
  //   await navigate('/');
  //   toastSuccess('Logged out!');
  // };

  const handleClick = name => evt => {
    const { pathname } = location;
    evt.preventDefault();
    // issue with Gatsby adding trailing slash to pathname, so pathname equality comparison fails
    if (name === 'login' && !pathname.includes(LOGIN_ROUTE)) {
      dispatch(openLoginModal()); // true, {}
      sendCustomGaEvent('Log In', 'Started', 'Login Began');
    } else if (name === 'register' && !pathname.includes(SIGNUP_ROUTE)) {
      dispatch(openSignupModal()); // true, {}
      sendCustomGaEvent('Sign Up', 'Started', 'Signup Began');
    } else if (name === 'menu') {
      dispatch(toggleMenu());
    }
  };

  /**
   * * Replicates handleCreateNewRipspot behaviour from Controls.js
   *
   * @param {*} evtA
   */
  const handleCreateRipspot = evtA => async evt => {
    evtA && evtA.preventDefault();
    evt && evt.preventDefault();
    // Close modal before starting creation process
    dispatch(closeRipspotMemorialModal());

    if (user) {
      // this  action clearsball existing pins and data to prep for new ripspot
      // sets flag that prevents public pins from displaying -- `editInProgress` a very important prop for UI changes
      if (!window.location.pathname.includes(MAP_CR_LOCATIONS_ROUTE)) {
        if (window.location.pathname.includes(MAP_SEARCH_ROUTE)) {
          await reachNavigate(MAP_CR_LOCATIONS_ROUTE, {
            state: { createNewRipspot: true },
          });
          dispatch(handleNewRipspots(ripspots));
        } else {
          navigate(MAP_CR_LOCATIONS_ROUTE, {
            state: { createNewRipspot: true },
          });
        }
      }
      sendCustomGaEvent(
        'Create A Ripspot',
        'Started Ripspot',
        'Create A Ripspot - Started Ripspot',
      );
    } else {
      dispatch(openSignupModal());
      sendCustomGaEvent('Sign Up', 'Started', 'Signup Began');
    }
  };

  const handleBurgerClick = useCallback(() => dispatch(toggleMenu()), [
    dispatch,
  ]);

  const handleNavigation = path => evt => {
    evt.preventDefault();
    navigate(path);
  };

  return (
    <StyledHeader>
      <Button link onClick={handleNavigation('/')}>
        <SVGWrapper>
          <LogoIcon />
        </SVGWrapper>
      </Button>
      <nav>
        <Mobile>
          <List>
            <BurgerMenu onClick={handleBurgerClick} />
          </List>
        </Mobile>
        <Desktop>
          <List>
            <>
              <li>
                <Button link onClick={handleNavigation(MAP_SEARCH_ROUTE)}>
                  Search
                </Button>
              </li>
              <li>
                <Button link onClick={handleCreateRipspot()}>
                  Create a Ripspot
                </Button>
              </li>
              {!user && (
                <>
                  <li style={{ marginRight: '25px' }}>
                    <Button link onClick={handleClick('login')}>
                      Sign In
                    </Button>
                  </li>
                </>
              )}

              {user && (
                <>
                  <li style={{ marginRight: '25px' }}>
                    <Button link onClick={handleNavigation('/account')}>
                      Hey, {user.firstName}
                    </Button>
                  </li>
                </>
              )}
            </>

            <li>
              <BurgerMenu onClick={handleBurgerClick} />
            </li>
          </List>
        </Desktop>
      </nav>
    </StyledHeader>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  ripspots: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

Header.defaultProps = {};

const mapStateToProps = ({ user, ripspots }) => ({ user, ripspots });
const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(Header);

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.Primary};
  padding: 15px;
  padding-left: 0px;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  body.fix-nav & {
    position: fixed;
    top: 0px;
    left: 0px;
  }
`;

const SVGWrapper = styled.div`
  width: 110px;
  margin-left: 15px;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  li {
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: #fff;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`;
