/**
 *
 * SocialMediaIcons
 *
 *
 */

import React from 'react';
import Link from 'components/Elements/Link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Facebook, Twitter, Instagram } from 'components/icons/brands/index';

const SocialMediaIcons = ({
  instagram = true,
  facebook = true,
  twitter = true,
}) => (
  <SocialContainer>
    {instagram && (
      <Link href="https://www.instagram.com/ripspotforever/">
        <Instagram width="25px" />
      </Link>
    )}
    {facebook && (
      <Link href="https://www.facebook.com/ripspotforever">
        <Facebook width="25px" />
      </Link>
    )}
    {twitter && (
      <Link href="https://twitter.com/ripspotforever">
        <Twitter width="25px" />
      </Link>
    )}
  </SocialContainer>
);

SocialMediaIcons.propTypes = {
  instagram: PropTypes.bool,
  facebook: PropTypes.bool,
  twitter: PropTypes.bool,
};

export default SocialMediaIcons;

const SocialContainer = styled.div`
  display: flex;
  width: 35%;
  margin: 2em 0;
  justify-content: space-between;
  ${({ theme }) => theme.media.min.tablet`
    width: 100%;
    
  `}
  svg {
    fill: ${({ theme }) => theme.colors.midGreen};
    width: auto;
    height: 30px;
    margin: 0 2px;
  }
`;
