import { useRef, useEffect } from 'react';

// Hook
export default (eventName, handler, element = document, throttle = 66) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  let _timeout;

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => {
        if (!_timeout) {
          _timeout = setTimeout(() => {
            _timeout = null;
            // will execute at a rate of 15fps@66ms
            savedHandler.current(event);
          }, throttle);
        }
      };

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
};

// Hook
export const useEventListenerDocumentBody = (
  eventName,
  handler,
  _,
  throttle = 66,
) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  let _timeout;

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = document.body && document.body.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => {
        if (!_timeout) {
          _timeout = setTimeout(() => {
            _timeout = null;
            // will execute at a rate of 15fps@66ms
            savedHandler.current(event);
          }, throttle);
        }
      };

      // Add event listener
      document.body.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        document.body.removeEventListener(eventName, eventListener);
      };
    },
    [eventName], // Re-run if eventName or element changes
  );
};

/**
 *
 * useEventListener
 *
 *   */
