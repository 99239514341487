import { useState, useEffect } from 'react';

// useScroll
export default () => {
  const [scrollTop, setScrollTop] = useState(0);

  let scrollTimeout;
  let scrollThrottler;

  const setDimensions = () => {
    const currentScrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
    setScrollTop(currentScrollTop);
  };

  useEffect(() => {
    scrollThrottler = () => {
      if (!scrollTimeout) {
        scrollTimeout = setTimeout(() => {
          scrollTimeout = null;
          // The setDimensions will execute at a rate of 15fps
          setDimensions();
        }, 66);
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollThrottler);
    setDimensions();

    return () => {
      window.removeEventListener('scroll', scrollThrottler);
      clearInterval(scrollTimeout);
    };
  }, []);

  return scrollTop;
};
