export const LOGIN_ROUTE = '/sign-in';
export const SIGNUP_ROUTE = '/register';
export const ACCOUNT_ROUTE = '/account';

export const MAP_SEARCH_ROUTE = '/map/search';
export const MAP_CR_LOCATIONS_ROUTE = '/map/create-ripspot/locations';
export const MAP_CR_DETAILS_ROUTE = '/map/create-ripspot/details';
export const MAP_CR_BIO_ROUTE = '/map/create-ripspot/bio';
export const MAP_CR_PHOTOS_ROUTE = '/map/create-ripspot/photos';
export const MAP_CR_REVIEW_ROUTE = '/map/create-ripspot/review';
export const MAP_CR_PAYMENT_ROUTE = '/map/create-ripspot/payment';
export const MAP_CR_CONFIRMATION_ROUTE = '/map/create-ripspot/confirmation';

export const FAQ_ROUTE = '/help';
export const TERMS_ROUTE = '/terms-and-conditions';

export const CHANGE_EMAIL_ROUTE = '/account/change-email';
export const CHANGE_PASSWORD_ROUTE = '/account/change-password';
export const FORGOT_PASSWORD_ROUTE = '/forgotPassword';
export const RESET_PASSWORD_ROUTE = '/passwordReset';
