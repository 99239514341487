import { SET_TOKEN } from 'actions/auth';

const initialState = {
  authToken: null,
};

export default function authReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    default:
      return state;
  }
}
