/**
 *
 * Slider
 *
 * Animated "slider" component animated using react-spring
 *
 * Expects a theme object to exist in app styled-comps
 *
 * Default background-color is theme.colors.Primary
 * Default color is theme.colors.Text
 *
 * optionally can pass in custom bgColor | color but must be lowercased
 * when placing it on animated.div DOM element to avoid error thrown
 *
 * e.g. bgColor must be placed on div as "bgcolor" to act as a "custom attribute"
 *
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

export const SLIDER_WIDTH = 400;

export const Slider = ({ open, children, flexgrow, bgColor, rtl = true }) => {
  const initialDx = rtl ? 50 : -100;
  // provide small initial translation to avoid
  // boxshadow bleeding onto screen edge
  // if fixed position, ${SLIDER_WIDTH + 15px} to account for overlaying scrollbar
  const dx = rtl ? `-${SLIDER_WIDTH}` : SLIDER_WIDTH;
  const translateX = open
    ? `translate3d(${dx}px,0,0)`
    : `translate3d(${initialDx}px,0,0)`;
  const springStyles = useSpring({
    transform: translateX,
    from: {
      transform: `translate3d(${initialDx}px,0,0)`,
    },
    delay: open ? 0 : 100, // slight delay to allow inner animated divs to start animating out
  });

  // issue with placing custom boolean attribute on DOM element,
  // need to explicitly return 1 or 0 to avoid throwing error
  return (
    <AnimatedSlider
      flexgrow={flexgrow ? 1 : 0}
      bgcolor={bgColor}
      style={springStyles}
    >
      {children}
    </AnimatedSlider>
  );
};

Slider.propTypes = {
  open: PropTypes.bool.isRequired,
  flexgrow: PropTypes.bool,
  bgColor: PropTypes.string,
  rtl: PropTypes.bool, // direction of slider
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Slider;

const AnimatedSlider = styled(animated.div)`
  ${({ flexgrow }) =>
    flexgrow &&
    css`
      ${({ theme }) => theme.styles.grow}
    `}
  background-color: ${({ bgcolor, theme }) =>
    bgcolor || '#fff' || theme.colors.Compliment};
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.styles.boxShadows[4]}
`;
