/**
 * App.js
 *
 * Implement Gatsby's Browser APIs in this file.
 *
 * This different from an SPA app.js where the router usual lives just fyi. use layout.js for all global stuff
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
// import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'react-toastify/dist/ReactToastify.css';

import Layout from 'layouts';
import { hydrateToken } from 'actions/auth';
import { toastSuccess, toastError, toastWarn, toastInfo } from 'utils/toastify';
import { initAPIClient } from 'utils/api';
import createStore from '../../store';

toast.configure({
  // autoClose: 8000,
  // draggable: false,
  // same as ToastContainer props
  style: {
    fontSize: '1rem',
  },
});

const isDev = process.env.GATSBY_NODE_ENV !== 'production';

// const getCookie = name => {
//   const cookies = new Cookies();
//   const cookie = cookies.get(name);
//   return cookie;
// };

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

let STRIPE_KEY;

if (isDev) {
  STRIPE_KEY = 'pk_test_hPfcEUHgUZITcgaDq9gjxN2j000Pl4HDqK';
} else {
  STRIPE_KEY = 'pk_live_i1EYmMOXekFS3BF2psFo5kqP00kMJdveFB';
}

const stripePromise = loadStripe(STRIPE_KEY);

const checkIfLoggedIn = dispatch => {
  // const jwt = getCookie('jwt');
  const jwtToken = localStorage.getItem('ripspot-token-jwt');
  // grabs user and if valid token,
  // gets user's data
  if (jwtToken) {
    try {
      // see if token has expired
      const decoded = jwtDecode(jwtToken);
      const now = Date.now() / 1000;
      const expiration = decoded.exp;
      if (expiration < now) {
        toastInfo('Token expired.');
        localStorage.removeItem('ripspot-token-jwt');
      } else {
        // token is valid, set in state
        dispatch(hydrateToken(jwtToken));
        initAPIClient(jwtToken);
      }
    } catch (err) {
      if (isDev) {
        console.error(err);
        toastError(err);
      }
    }
  } else {
  }
};

// let stripe
// used to store ref to dispatch actions outside of wrapRootElement
let store = null;
export const wrapApp = ({ element, ...rest }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  store = createStore();
  // const data = store.getState();

  checkIfLoggedIn(store.dispatch);

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Layout>{element}</Layout>
      </Elements>
    </Provider>
  );
};
wrapApp.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

// TOD: Finsih ap!!!!!

export const onAppRouteUpdate = ({ location, prevLocation }) => {};
