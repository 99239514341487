/**
 *
 * Auth Actions
 *
 *
 */
// import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';

import { setUser } from 'actions/user';
import { resetAllForLogout } from 'actions/ripspots';
import { initAPIClient } from 'utils/api';
import { getUserById } from 'utils/api/users';
import { refreshAuthToken } from 'utils/api/auth';

import { setEditInProgress } from './ripspots';

export const LOG_IN = 'LOG_IN';
export const logIn = () => (dispatch, getState) => {
  // http call to log user in
  // on success
  //  dispatch logged in action, set user in redux
  // on fail
  //   return with error message
};

export const SET_TOKEN = 'LOG_IN';
export const setToken = authToken => ({
  type: SET_TOKEN,
  authToken,
});

export const hydrateToken = token => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const decoded = jwtDecode(token);
    const { exp } = decoded;
    // check expiration
    if (exp < Date.now() / 1000) {
      localStorage.removeItem('ripspot-token-jwt');
      resolve(null);
    } else {
      // TOKEN GOOD!
      // refresh token
      const newToken = await refreshAuthToken(token);
      initAPIClient(newToken);
      const newDecoded = jwtDecode(newToken);
      const user = await getUserById(newDecoded.user.id);
      dispatch(setUser(user));
      resolve(user);
    }
  });

/**
 * Fired after successful response from login API
 * Receives authToken, stores it, decodes it,
 * grabs user id and fetches user's info,
 * sets user in redux
 *
 * @param {*} authToken
 */
export const loginComplete = authToken => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      // TODO: Might want to lock cookie down to specific endpoint to minimize cookie exposure
      // const cookies = new Cookies();
      // cookies.set('jwt', authToken, { page: '/' }); // page: '/' exposes cookie on any page
      localStorage.setItem('ripspot-token-jwt', authToken);
      dispatch(setToken(authToken));
      initAPIClient(authToken);

      //
      const decoded = jwtDecode(authToken);

      //
      const user = await getUserById(decoded.user.id);
      dispatch(setUser(user));

      resolve();
    } catch (err) {
      reject(err);
    }
  });

export const logout = () => (dispatch, getState) => {
  // remove token
  localStorage.removeItem('ripspot-token-jwt');
  dispatch(setToken(null));
  // remove user
  dispatch(setUser(null));
  // make sure map knows we are out of edit Modality and clear all ripspot/explore map related data
  dispatch(resetAllForLogout());
};
