/**
 *
 * LogoutIcon
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

export const LogoutIcon = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    height={height}
    width={width}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={fill}
        d="M82.6,48H38c-1.1,0-2,0.9-2,2s0.9,2,2,2h44.6L71.2,63.4c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6   l14.8-14.8v-0.6c0.1-0.2,0.2-0.5,0.2-0.8s-0.1-0.5-0.2-0.8v-0.6L74,33.7c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8L82.6,48z"
      />
      <path
        fill={fill}
        d="M21.5,80h36.9c3.6,0,6.5-2.9,6.5-6.5V64c0-1.1-0.9-2-2-2s-2,0.9-2,2v9.5c0,1.4-1.1,2.5-2.5,2.5H21.5   c-1.4,0-2.5-1.1-2.5-2.5V26.5c0-1.4,1.1-2.5,2.5-2.5h36.9c1.4,0,2.5,1.1,2.5,2.5V36c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.5   c0-3.6-2.9-6.5-6.5-6.5H21.5c-3.6,0-6.5,2.9-6.5,6.5v46.9C15,77.1,17.9,80,21.5,80z"
      />
    </g>
  </svg>
  // Door icon!
  // <svg
  //   height={height}
  //   width={width}
  //   style={style}
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  //   version="1.1"
  //   x="0px"
  //   y="0px"
  //   viewBox="0 0 100 100"
  //   xmlSpace="preserve"
  // >
  //   <g>
  //     <polygon
  //       fill={fill}
  //       points="68.7,36.4 78.6,47 60,47 60,55 78.6,55 68.7,65.6 74.6,71.1 93.3,51 74.6,30.9  "
  //     />
  //     <polygon
  //       fill={fill}
  //       points="35,47 60,47 60,14 10,14 10,86 60,86 60,55 35,55  "
  //     />
  //   </g>
  // </svg>
);

LogoutIcon.propTypes = SVGPropTypes;

export default LogoutIcon;
