import { SET_STRIPE_INSTANCE, CLEAR_STRIPE_INSTANCE } from 'actions/stripe';

const initialState = null;

export default function ripspotsReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_STRIPE_INSTANCE:
      return action.stripe;
    case CLEAR_STRIPE_INSTANCE:
      return initialState;
    default:
      return state;
  }
}
