/**
 *
 *  useRouter
 *
 *
 */

import { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

const useRouter = () => {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen(({ location }) => {
      const newState = { ...initialState, location };
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, []);

  return state;
};

export default useRouter;
