/**
 *
 * ButtonIcon
 *
 * Uses CSS to apply SVG fills to achieve hover animations
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonIcon = ({
  Icon,
  fill,
  hoverFill,
  onClick,
  onMouseOut,
  onMouseOver,
  width,
  height,
  btnWidth,
  style,
  type,
  btnStyle,
  className,
  ...rest
}) => {
  const btnStyles = {
    ...btnStyle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  if (btnWidth) {
    btnStyles.width = btnStyles;
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <Button
      {...rest}
      className={className}
      type={type || 'button'}
      style={btnStyles}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      fill={fill}
      hoverFill={hoverFill}
    >
      <Icon width={width} height={height} style={style} />
    </Button>
  );
};
ButtonIcon.propTypes = {
  Icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  btnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  btnStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default memo(ButtonIcon);

const Button = styled.button`
  background-color: transparent;
  padding: 2px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  svg {
    fill: ${({ fill }) => fill || '#fff'};
    transition: fill 0.3s;
  }

  &:hover {
    svg {
      fill: ${({ hoverFill }) => hoverFill || 'grey'};
    }
  }
`;
