/**
 *
 * Desktop breakpoint Component wrapper
 *
 *
 */

import styled from 'styled-components';

export const Desktop = styled.div`
  /* was max.tablet */
  ${({ theme }) => theme.media.max.mobile`
    display: none;
  `}
`;

export default Desktop;
