import { SET_USER, UPDATE_USER_RIPSPOT } from 'actions/user';

const initialState = null;

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case UPDATE_USER_RIPSPOT:
      const newRipspots = state.ripspots.map(ripspot => {
        if (ripspot.id === action.ripspotId) {
          return action.ripspot;
        }
        return ripspot;
      });
      return {
        ...state,
        ripspots: newRipspots,
      };
    default:
      return state;
  }
};

export default userReducer;
