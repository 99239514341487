/**
 *
 * Globe
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const Globe = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill={fill}
      d="M40.4 28.2l-1.6 2.2-1.6-2.2c-.7-1-2.6-3.6-4.5-6.7-.1 0-.2 0-.3.1l-4.9 3.9c-.2.1-.3.3-.3.5v3.8c0 .2.1.3.2.5l1.5 1.5c.1.1.3.2.4.2l3.9.4c.1 0 .2.1.3.1l1.3.8c.3.2.4.7.1 1-.2.2-.2.6 0 .9l1.3 1.5c.1.1.1.2.1.3-3.3 5.1-9 8.5-15.5 8.5-1.9 0-3.7-.3-5.4-.8l3.7-3.9c.1-.1.2-.3.2-.4l.4-2.3c0-.2.1-.3.2-.4l.3-.3c.3-.3.3-.9-.1-1.1l-2.7-1.9c-.1-.3-.2-.4-.4-.4h-.7c-.4 0-.7-.3-.8-.6l-.1-.8c-.1-.4-.4-.6-.8-.6h-.9c-.5 0-.9-.5-.7-.9V31c.1-.5-.2-.9-.7-1l-2.3-.1c-.1 0-.2 0-.3-.1l-.7-.3c-.2-.1-.4-.1-.6 0l-1.2.5H7l-1.4.2c-.5.1-1-.4-.8-.9l.2-.5c.1-.5-.2-.9-.7-.9-.4 0-.8-.3-.8-.8 0-.4-.3-.7-.8-.7h-.2c0-1 .1-2 .3-2.9H4c.3 0 .5.1.6.3l.5.7c.1.2.4.3.6.3h.9L6 23.3c-.1-.3 0-.6.2-.8l1.5-1.3c.1-.1.2-.2.2-.3l.3-1c.1-.3.4-.5.7-.5.4 0 .7-.3.7-.7v-.4c0-.3.1-.5.4-.6l3.8-2.1c.4-.2.5-.7.3-1.1l-2.4-3.3c1.4-.8 2.9-1.4 4.6-1.8v.5c.1.5.6.9 1.2.9h.5c.5 0 .9.3 1.1.7l.6 1.1c.2.4.6.6 1.1.6h1.4c.3 0 .5-.1.7-.2l1.1-.8c.3-.2.6-.3.9-.2l.4.1c.4.1.9-.1 1.2-.5l.6-.7c.2-.2.3-.5.3-.8.1 0 .2.1.3.1 0-.3-.1-.5-.1-.8 0-.7.1-1.3.2-1.9-2.1-.7-4.4-1.1-6.8-1.1C9.4 6.2 0 15.6 0 27.1S9.4 48 20.9 48c11.5 0 20.9-9.4 20.9-20.9v-.9c-.6.9-1.1 1.6-1.4 2z"
    />
    <path
      fill={fill}
      d="M48 9.3C48 4.1 43.9 0 38.7 0c-5.1 0-9.2 4.1-9.2 9.3 0 5.1 9.2 17.7 9.2 17.7S48 14.4 48 9.3zm-15.5 0c0-3.4 2.8-6.2 6.2-6.2 3.4 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2c-3.4 0-6.2-2.8-6.2-6.2z"
    />
  </svg>
);

Globe.propTypes = SVGPropTypes;

export default Globe;
