import PropTypes from 'prop-types';

import { isMobile } from 'react-device-detect';

export const listenForTouch = setIsTouch => {
  if (
    isMobile &&
    ('ontouchstart' in window ||
      window.TouchEvent ||
      (window.DocumentTouch && document instanceof DocumentTouch))
  ) {
    // we could use a class
    document.body.classList.add('user-is-touching');

    // or set some global variable
    window.USER_IS_TOUCHING = true;

    // or set your app's state however you normally would
    setIsTouch(true);
  }
  // window.addEventListener(
  //   'touchstart',
  //   function onFirstTouch() {
  //     // we could use a class
  //     document.body.classList.add('user-is-touching');

  //     // or set some global variable
  //     window.USER_IS_TOUCHING = true;

  //     // or set your app's state however you normally would
  //     setIsTouch(true);

  //     // we only need to know once that a human touched the screen, so we can stop listening now
  //     window.removeEventListener('touchstart', onFirstTouch, false);
  //   },
  //   false,
  // );
};

listenForTouch.propTypes = {
  setIsTouch: PropTypes.func.isRequired,
};

export const listenForHover = setHasHover => {
  window.addEventListener(
    'pointerdown',
    function onFirstPointer(e) {
      // we could use a class
      document.body.classList.add('user-has-pointer');
      setHasHover();
      window.POINTER_SIZE = e.height;
      window.removeEventListener('pointerdown', onFirstPointer, false);
    },
    false,
  );
};

listenForHover.propTypes = {
  setHasHover: PropTypes.func.isRequired,
};
