/**
 *
 * Checkout Reducer
 *
 *
 */

import {
  SET_IS_PAYING,
  SET_TEMP_PAYMENT_INFO,
  SET_IS_PROCESSING,
  SET_TOTAL,
  RESET_CHECKOUT,
} from 'actions/checkout';

const initialState = {
  tempPaymentInfo: '',
  isProcessing: false,
  isPaying: false,
};

export default function checkoutReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_TEMP_PAYMENT_INFO:
      return {
        ...state,
        tempPaymentInfo: action.tempPaymentInfo,
      };
    case SET_TOTAL:
      return {
        ...state,
        tempPaymentInfo: {
          ...state.tempPaymentInfo,
          amount: action.total,
        },
      };
    case SET_IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case SET_IS_PAYING:
      return {
        ...state,
        isPaying: action.isPaying,
      };
    case RESET_CHECKOUT:
      return initialState;
    default:
      return state;
  }
}
