export const SET_STRIPE_INSTANCE = 'SET_STRIPE_INSTANCE';
export const setStripeInstance = stripe => ({
  type: SET_STRIPE_INSTANCE,
  stripe,
});

export const CLEAR_STRIPE_INSTANCE = 'CLEAR_STRIPE_INSTANCE';
export const clearStripeInstance = () => ({
  type: CLEAR_STRIPE_INSTANCE,
});
