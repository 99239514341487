/**
 *
 * Checkout Actions
 *
 *
 */

export const SET_TEMP_PAYMENT_INFO = 'SET_TEMP_PAYMENT_INFO';
export const setTempPaymentInfo = tempPaymentInfo => ({
  type: SET_TEMP_PAYMENT_INFO,
  tempPaymentInfo,
});

export const SET_TOTAL = 'SET_TOTAL';
export const setTotal = total => ({
  type: SET_TOTAL,
  total,
});

export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const setIsProcessing = isProcessing => ({
  type: SET_IS_PROCESSING,
  isProcessing,
});

export const SET_IS_PAYING = 'SET_IS_PAYING';
export const setIsPaying = isPaying => ({
  type: SET_IS_PAYING,
  isPaying,
});

export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const resetCheckout = () => ({
  type: RESET_CHECKOUT,
});
